import Ilma from "../pages/ilma/Ilma";
import Frontpage from '../pages/frontpage/Frontpage';
import Tuli from '../pages/tuli/Tuli';
import Maa from '../pages/maa/Maa';
import Vesi from '../pages/vesi/Vesi';
import Oulu from '../pages/Oulu2026/Oulu';
import MobileNavbar from './Mobilenavbar/Mobilenavbar.jsx';
import Scenebg from "./Scenebg"

import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import gsap from "gsap";
import LanguageGlobe from './Language/LanguageGlobe';
import useWindowSize from "./useWindowSize.jsx";

import useAnimationStore from "../zustand/useAnimationStore.js";

function Content() {

    const { width, height} = useWindowSize();
    const [color, setColor] = useState(0xFFFFFF);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const [breakpoint, setBreakpoint]= useState(null);
    const h1Ref = useRef(null);

    const scene = useRef(null);
    const model = useRef(null);
    const camera = useRef(null);

    const { headingAnimationFinished, setHeadingAnimationFinished } = useAnimationStore();

    const receiveSceneAndModelFromChild = (sceneRef, modelRef, cameraRef) => { 
      scene.current = sceneRef;
      model.current = modelRef;
      camera.current = cameraRef;
    }

    const receiveColorRefFromChild = (colorRef) => {
      setColor(colorRef);
    };

    const navAnim = (duration) => {
      gsap.to("nav", {
          bottom: 0,
          duration: duration
      })
    }

    useLayoutEffect(()=> {
      if (headingAnimationFinished) {
          gsap.to(".globeWrapper", {
              opacity: 1,
              duration: 2,
              delay: 1
        })
      }
      if (width <= 768 && headingAnimationFinished) {
          navAnim(1)
      } 
    }, [width, headingAnimationFinished])

  //brekapoint logic
  useEffect(()=> {
    if(headingAnimationFinished) {
  
      switch (true) {
        case window.innerWidth<501: 
          setBreakpoint(500)
        break;
        case window.innerWidth<769: 
          setBreakpoint(768)
        break;
        case window.innerWidth<=1121: 
          setBreakpoint(1121)
        break;
        case window.innerWidth>1121:
          setBreakpoint(9999)
        break;
      }
    }
  },[window.innerWidth, headingAnimationFinished])

    // Disable scrolling until headingAnimationFinished is true
    useLayoutEffect(() => {
      const preventDefault = (e) => e.preventDefault();

      if (!headingAnimationFinished) {
        document.body.classList.add('no-scroll');
        window.addEventListener('touchmove', preventDefault, { passive: false });
      } else {
        document.body.classList.remove('no-scroll');
        window.removeEventListener('touchmove', preventDefault);
      }

      return () => {
        document.body.classList.remove('no-scroll');
        window.removeEventListener('touchmove', preventDefault);
      };
  }, [headingAnimationFinished]);


    return (
        <>
          <h1 id="front-title" ref={h1Ref}>DELTΔ LIFE</h1>
          <LanguageGlobe isMobile={isMobile}/>
          <Scenebg winWid={width} headingAnimationFinished={headingAnimationFinished} passColor={receiveColorRefFromChild} passSceneModel={receiveSceneAndModelFromChild}/>
          <Frontpage isMobile={isMobile} winWid={width} h1Ref={h1Ref}/>
          { headingAnimationFinished &&
            <>
                <Maa breakpoint={breakpoint}  isMobile={isMobile} winWid={width} show={headingAnimationFinished}/>
                <Vesi breakpoint={breakpoint}  isMobile={isMobile} winWid={width} show={headingAnimationFinished}/>
                <Ilma breakpoint={breakpoint}  isMobile={isMobile} winWid={width} show={headingAnimationFinished}/>
                <Tuli breakpoint={breakpoint} isMobile={isMobile} winWid={width} show={headingAnimationFinished}/>
                <Oulu model={model} scene={scene} camera={camera} h1Ref={h1Ref} />
            </> 
          }
          {width <= 768 && headingAnimationFinished && <MobileNavbar screenIndex={color}/>}
        </>
    );
}

export default Content;

