// src/App.jsx
import React, { useLayoutEffect } from 'react';
import './styles/App.scss';
import Content from './components/Content';
import useOrbitStore from './zustand/useOrbitStore';

function App() {

  const {
    orbitControlsEnabled,
    viewportAtTop,
    setOrbitControlsEnabled,
    setViewportAtTop,
  } = useOrbitStore();
  
  useLayoutEffect(() => { //kun sivu ladattu

    const handleScroll = () => { //uusi funktio joka ajetaan aina kun scroll tapahtuu
      if (window.scrollY === 0) { //jos scrollattu nollaan
        setViewportAtTop(true)
        setOrbitControlsEnabled(true)
      } else { //jos scrolli muu kuin nolla
        setViewportAtTop(false)
        setOrbitControlsEnabled(false)
      }
    };

    window.addEventListener('scroll', handleScroll); //lisää scrolliin tämä funktio

    return () => {
      window.removeEventListener('scroll', handleScroll); //kun komponentti unmounttaa, tapetaan tämä listener
    };
  }, [useOrbitStore]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0) //aina kun sivu lataa uudestaan scrollataan ylös
  }, [])

  return (
    <>
      <Content />
    </>
  )
}

export default App
