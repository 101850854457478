import React, { useRef, useState, useLayoutEffect } from 'react';
import SplitType from 'split-type';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/all";
import './Ilma.scss';
import { useTranslation } from "react-i18next";
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, useGSAP); 

export default function Ilma(props) {
  const [t, i18n] = useTranslation("global");
  const [textVar, setTextVar]= useState("");
  const [playShit, setPlayShit]= useState(false);
  const [breakpoint, setBreakpoint]= useState(null);

  const h2Ref = useRef(null)
  const textAreaRef = useRef(null);
  const section5Ref = useRef(null);
  const textContentRef = useRef(null);
  const logoGridRef = useRef(null);

  const currentLng = i18n.language;

  useLayoutEffect(()=> {
    if (props.show) {
      console.log("BREAKPOINT FIRING")
      setBreakpoint(props.breakpoint)
    }
  }, [props.breakpoint, props.show])

  useLayoutEffect(() => {

    setTextVar(t("aText"));

  }, [t, i18n.language]);

  useGSAP(()=>{

    if (props.show) {

      console.log("Käsi animi firing")

      const hand = document.querySelector('#hand');
      const viivatPaths = document.querySelectorAll('#viivat path');

      gsap.timeline({
        scrollTrigger: {
          trigger: section5Ref.current,
          start: "center-=20% center",
          onEnter: () => {
            gsap.to("#guitarLogo", {
              opacity: 1,
              duration: 1,
            });
            hand.classList.add('heilutus');
          },
          once: true,
        }
      });

      // Event listener for the animationend event of the hand element
      hand.addEventListener('animationend', 
        // Call the function to apply animation to path elements after hand animation ends
        applyPathAnimation
      );

      // Function to apply animation to path elements
      function applyPathAnimation() {
        viivatPaths.forEach(path => {
            path.style.animation = 'drawPath 4s forwards'; // Example animation
        });

        gsap.to(["#Air-guitar", "#world-championship"], {
          opacity: 1,
          duration: 1.5,
          onComplete: () => {
            gsap.to("#TM", {
              opacity:1,
              duration: 1,
            })
            gsap.to(textAreaRef.current, {
              opacity: 1,
              duration: 2
            });
            setPlayShit(true)
          }
        });
      }
    }

  },[props.show])

  useLayoutEffect(()=>{

    if (playShit) {
      let text = "";
      if (currentLng === "fi") {
        text = "ILMA tekijät";
      } else if (currentLng === "en") {
        text = "AIR creators";
      }

    h2Ref.current.innerHTML = text;

    const splitText = new SplitType(h2Ref.current, {
      types: 'chars',
      charClass: 'charIlma'
    });
    
    const chars = splitText.chars;

      let shit = gsap.to(chars, {
        opacity: 1,
        stagger: 0.25,
        duration: 1,
        delay: 0.5
      });
    

      return () => {
        shit.kill();
        splitText.revert();
      };

    }

  }, [playShit, currentLng])

  //text fading
  useLayoutEffect(()=> {

    if (props.show) {

      console.log("text fading firing")

      let timelines = []; 
      let splitText; 

      const breakpoints = [
        { 
          width: 500, 
          animationConfig: { start: "top-=150% center-=10%", end: "bottom-=200% center-=10%" }
        },
        { 
          width: 768, 
          animationConfig: { start: "top-=150% center-=5%", end: "bottom-=200% center-=5%" }
        },
        { 
          width: 1121, 
          animationConfig: { start: "top-=100% center+=20%", end: "bottom-=100% center+=20%" }
        },
        { 
          width: 9999, 
          animationConfig: { start: "top-=100% top+=20%", end: "bottom-=100% top+=20%" }
        }
      ];

      if(breakpoint !== null) {
      setTimeout(() => {
        const desiredBpConfig = breakpoints.find(bp => window.innerWidth <= bp.width);
        
        if (desiredBpConfig) {
          splitText = new SplitType(textContentRef.current, {
            types: "lines"
          });
          
          const lines = splitText.lines;
          //text anim
          lines.forEach((line, index) => {
            const timeline = gsap.to(line, {
              opacity: 0,
              scrollTrigger: {
                trigger: line,
                ...desiredBpConfig.animationConfig,
                scrub: true,
                // markers: {startColor: "blueviolet", endColor: "blueviolet", fontSize: "18px", indent: 100}
                },
              });
              timelines.push(timeline); 
            });
          } 
        }, props.isMobile ? 100 : 0); 
      } 
    
      return () => {
        timelines.forEach((timeline) => {
          timeline.kill(); // Kill each timelin
        });
        // Revert SplitType instances to their original state
        if (splitText) splitText.revert();
      };
      
    } //if props show
  },[props.show, i18n.language, props.winWid, breakpoint, props.isMobile ])

  // //h2 fading
  useLayoutEffect(()=> {

    if (props.show) {

      console.log("h2 fading firing")

      const breakpoints = [
            { 
              width: 500, 
              h2Config: {start:"top center-=5%", end:"bottom center-=5%"}
            },
            { 
              width: 768, 
              h2Config: {start:"top center", end:"bottom center"}
            },
            { 
              width: 1121, 
              h2Config: {start:"top-=100% center+=20%", end:"bottom-=100% center+=20%"}
            },
            { 
              width: 9999, 
              h2Config: { start: "top top+=20%", end: "bottom top+=20%" }
            }
          ];

          const desiredBpConfig = breakpoints.find(bp => breakpoint <= bp.width);
          let h2tl = null;

          if(breakpoint !== null) {
            
            if (desiredBpConfig) {
              // h2 anim
              h2tl = gsap.fromTo(
                h2Ref.current, 
                { opacity: 1 },
                {
                  opacity: 0,
                  scrollTrigger: {
                    trigger: h2Ref.current,
                    ...desiredBpConfig.h2Config,
                    scrub:true,
                    // markers: {startColor: "white", endColor: "white", fontSize: "18px", indent: 0}
                  }
                })
            } 

            return () => {
              if(h2tl !==null) {
                h2tl.kill();
              }
            };
          }
    }
  },[props.show, breakpoint])

  // //logo fading
  useLayoutEffect(()=> {

    if (props.show) {
      console.log("logo fading firing")
      const breakpoints = [
        { 
          width: 500, 
          logoAnimationConfig: { start: "top+=100% center", end: "bottom+=100% center" } 
        },
        { 
          width: 768, 
          logoAnimationConfig: { start: "top center", end: "bottom-=50% center" } 
        }
      ];
    
      const desiredBpConfig = breakpoints.find(bp => breakpoint <= bp.width);
    
      let logoAnim = null;

      if (breakpoint !== null) {
        if (desiredBpConfig) {

          logoAnim = gsap.fromTo(
            logoGridRef.current,
            { opacity: 1 },  // The initial state (from)
            { 
              opacity: 0,  // The final state (to)
              scrollTrigger: {
                trigger: logoGridRef.current,
                ...desiredBpConfig.logoAnimationConfig,  // Use logoAnimationConfig for logo animation
                scrub: true,
                // markers: {startColor: "blue", endColor: "blue", fontSize: "18px", indent: 200}
              }
            });
            
          } else {
            gsap.to(logoGridRef.current, {opacity:1, duration: 1})
          }

          return () => {
            if(logoAnim !==null) {
              logoAnim.kill();
            }
          };
      }
    } //props show
  },[breakpoint, props.show])

  return (
    <section className="full-width" id="section5" ref={section5Ref}>
      <div className='content'>
        <div id="logoGridA" ref={logoGridRef}>
          <a id="guitarLogo" aria-label="Air Guitar World Championships" href="https://airguitarworldchampionships.com/en/home/" target='_blank' rel="noreferrer">
            <svg data-name="Layer 3" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 760.06 228.78" style={{overflow:"visible"}}>
              <g id="viivat">
                <path className="cls-4" d="M193.61,59.26c24.09,0,24.09,10,48.17,10s24.09-10,48.17-10,24.09,10,48.17,10,24.08-10,48.17-10,24.09,10,48.17,10,24.09-10,48.17-10,24.09,10,48.17,10,24.09-10,48.18-10,24.09,10,48.18,10"/>
                <path className="cls-4" d="M193.61,82.62c24.09,0,24.09,10,48.17,10s24.09-10,48.17-10,24.09,10,48.17,10,24.08-10,48.17-10,24.09,10,48.17,10,24.09-10,48.17-10,24.09,10,48.17,10,24.09-10,48.18-10,24.09,10,48.18,10"/>
                <path className="cls-4" d="M193.61,105.48c24.09,0,24.09,10,48.17,10s24.09-10,48.17-10,24.09,10,48.17,10,24.08-10,48.17-10,24.09,10,48.17,10,24.09-10,48.17-10,24.09,10,48.17,10,24.09-10,48.18-10,24.09,10,48.18,10"/>
                <path className="cls-4" d="M193.61,128.34c24.09,0,24.09,10,48.17,10s24.09-10,48.17-10,24.09,10,48.17,10,24.08-10,48.17-10,24.09,10,48.17,10,24.09-10,48.17-10,24.09,10,48.17,10,24.09-10,48.18-10,24.09,10,48.18,10"/>
                <path className="cls-4" d="M193.61,151.58c24.09,0,24.09,10,48.17,10s24.09-10,48.17-10,24.09,10,48.17,10,24.08-10,48.17-10,24.09,10,48.17,10,24.09-10,48.17-10,24.09,10,48.17,10,24.09-10,48.18-10,24.09,10,48.18,10"/>
                <path className="cls-4" d="M193.61,174.81c24.09,0,24.09,10,48.17,10s24.09-10,48.17-10,24.09,10,48.17,10,24.08-10,48.17-10,24.09,10,48.17,10,24.09-10,48.17-10,24.09,10,48.17,10,24.09-10,48.18-10,24.09,10,48.18,10"/>
                <path className="cls-4" d="M193.61,198.05c24.09,0,24.09,10,48.17,10s24.09-10,48.17-10,24.09,10,48.17,10,24.08-10,48.17-10,24.09,10,48.17,10,24.09-10,48.17-10,24.09,10,48.17,10,24.09-10,48.18-10,24.09,10,48.18,10"/>
                <path className="cls-4" d="M193.61,36.4c24.09,0,24.09,10,48.17,10s24.09-10,48.17-10,24.09,10,48.17,10,24.08-10,48.17-10,24.09,10,48.17,10,24.09-10,48.17-10,24.09,10,48.17,10,24.09-10,48.18-10,24.09,10,48.18,10"/>
              </g>
              <g id="TM">
                <path className="cls-1" d="M702.16,6.97c-2.5,0-4.64.92-6.42,2.76-1.79,1.85-2.69,4.04-2.69,6.58s.9,4.82,2.69,6.67c1.78,1.84,3.95,2.76,6.5,2.76s4.68-.92,6.48-2.75c1.78-1.84,2.68-4.06,2.68-6.67,0-1.39-.35-2.83-1.05-4.32-.69-1.39-1.61-2.5-2.76-3.32-1.61-1.15-3.42-1.72-5.42-1.72M713.16,16.33c0,3.05-1.06,5.65-3.19,7.79-2.13,2.14-4.71,3.21-7.73,3.21s-5.61-1.07-7.76-3.22c-2.13-2.14-3.2-4.74-3.2-7.8,0-1.69.35-3.24,1.04-4.63.7-1.48,1.68-2.74,2.92-3.77,2.04-1.68,4.33-2.53,6.87-2.53,1.68,0,3.21.32,4.58.95,1.94.85,3.5,2.19,4.68,4.03,1.19,1.85,1.79,3.84,1.79,5.97ZM700.69,15.65h.41c1.73,0,2.59-.6,2.6-1.79,0-1.11-.84-1.67-2.51-1.67h-.49v3.46ZM703.3,17.39l4.11,5.29h-2.95l-3.76-5.01v5.01h-2.42v-12.65h2.53c1.77,0,3.05.31,3.86.94.9.73,1.35,1.68,1.35,2.85,0,.79-.24,1.53-.73,2.2-.48.66-1.1,1.09-1.84,1.31l-.16.05Z"/>
                <polyline className="cls-1" points="730.35 8.72 730.35 26.82 727.17 26.82 727.17 8.72 722.32 8.72 722.32 5.73 735.19 5.73 735.19 8.72 730.35 8.72"/>
                <polyline className="cls-1" points="736.9 26.82 741.34 4.14 748.58 20.54 756.08 4.14 760.06 26.82 756.8 26.82 754.77 14.09 748.52 27.79 742.46 14.07 740.19 26.82 736.9 26.82"/>
              </g>
              <g id="Air-guitar">
                <path className="cls-1" d="M307.99,101.71h-11.97l3.65-22.91,1.83-12.99h.23l1.94,12.99,4.33,22.91M293.29,43.69l-19.95,85.94h16.87l3.08-14.47h17.21l3.19,14.47h18.12l-20.63-85.94h-17.9Z"/>
                <rect className="cls-1" x="337.05" y="43.69" width="16.98" height="85.94"/>
                <path className="cls-1" d="M381.49,57.71h2.28c8.55-.46,11.51,4.56,11.51,10.71,0,7.07-3.08,12.31-10.83,12.31l-2.96-.11v-22.91M381.49,129.64v-39.67h.23l14.93,39.67h17.89l-14.93-39.1c8.44-4.67,12.2-12.77,12.2-22.23,0-23.48-19.38-24.62-30.21-24.62h-17.1v85.94h16.98Z"/>
                <path className="cls-1" d="M476.99,81.76v15.96h10.83c0,5.93-1.37,17.55-10.03,17.55-12.54,0-13.11-19.72-13.11-28.61,0-8.09,1.14-29.29,12.65-28.61,6.5,0,7.86,6.73,8.66,11.06l16.53-6.38c-3.65-12.2-12.19-20.4-25.3-20.4-24.28,0-30.66,24.96-30.66,44.79s7.52,43.88,30.66,43.88,27.58-24.28,27.58-42.51v-6.73h-27.81"/>
                <path className="cls-1" d="M511.75,43.69v63.83c0,16.07,9.46,23.48,25.31,23.48,24.51,0,25.53-15.5,25.53-24.62v-62.69h-16.98v59.27c-.11,6.38-.11,12.65-8.43,12.65-9.23,0-8.43-9.8-8.43-16.3v-55.62h-16.99"/>
                <rect className="cls-1" x="572.6" y="43.69" width="16.98" height="85.94"/>
                <polyline className="cls-1" points="595.73 43.69 595.73 59.65 609.07 59.65 609.07 129.64 626.05 129.64 626.05 59.65 639.51 59.65 639.51 43.69 595.73 43.69"/>
                <path className="cls-1" d="M675.17,101.71h-11.97l3.65-22.91,1.83-12.99h.23l1.93,12.99,4.33,22.91M660.47,43.69l-19.95,85.94h16.87l3.08-14.47h17.21l3.19,14.47h18.12l-20.63-85.94h-17.89Z"/>
                <path className="cls-1" d="M721.21,57.71h2.28c8.55-.46,11.51,4.56,11.51,10.71,0,7.07-3.08,12.31-10.83,12.31l-2.96-.11v-22.91M721.21,129.64v-39.67h.23l14.93,39.67h17.89l-14.93-39.1c8.44-4.67,12.2-12.77,12.2-22.23,0-23.48-19.38-24.62-30.2-24.62h-17.1v85.94h16.98Z"/>
              </g>
              <g id="world-championship">
                <path className="cls-1" d="M271.06,147.5l7.8,38.69h6.67l4.41-20.42c.56-2.36.82-4.72,1.23-7.08h.1c.36,2.36.82,4.72,1.08,7.08l3.95,20.42h6.62l8-38.69h-8l-3.03,18.58c-.46,2.72-.51,5.39-.77,8.11h-.1l-4.93-26.68h-6.05l-3.85,18.47c-.56,2.72-.77,5.49-1.13,8.21h-.1c-.26-2.72-.36-5.49-.82-8.21l-3.28-18.47h-7.8"/>
                <path className="cls-1" d="M320.84,166.85c0-3.44.36-12.88,5.44-12.88s5.44,9.44,5.44,12.88c0,3.08-.36,12.88-5.44,12.88s-5.44-9.8-5.44-12.88M326.28,146.89c-6.82,0-13.6,5.44-13.6,19.96s6.77,19.96,13.6,19.96,13.6-5.44,13.6-19.96-6.77-19.96-13.6-19.96Z"/>
                <path className="cls-1" d="M351.57,153.81h1.03c3.85-.21,5.18,2.05,5.18,4.82,0,3.18-1.38,5.54-4.87,5.54l-1.33-.05v-10.32M351.57,186.19v-17.86h.1l6.72,17.86h8.06l-6.72-17.6c3.8-2.1,5.49-5.75,5.49-10,0-10.57-8.72-11.08-13.6-11.08h-7.7v38.69h7.65Z"/>
                <polyline className="cls-1" points="371.11 147.5 371.11 186.19 386.76 186.19 386.76 179.01 378.76 179.01 378.76 147.5 371.11 147.5"/>
                <path className="cls-1" d="M397.89,179.32v-24.94c6.31,0,7.85,7.8,7.85,12.73s-1.69,12.52-7.85,12.21M397.07,186.19c12.06,0,16.32-8.01,16.32-19.04,0-21.6-16.01-19.65-17.35-19.65h-5.8v38.69h6.83Z"/>
                <path className="cls-1" d="M449.29,147.76c-1.28-.46-2.57-.72-3.9-.72-10.42,0-16.78,9.85-16.78,19.45,0,10.16,6.11,20.11,17.14,20.11,1.23,0,2.41-.2,3.54-.67v-7.23c-.98.31-1.95.57-2.93.57-6.67,0-9.6-7.08-9.6-12.83s2.93-12.06,9.65-12.06c.97,0,1.9.15,2.87.41v-7.03"/>
                <polyline className="cls-1" points="453.18 147.5 453.18 186.19 460.83 186.19 460.83 169.82 468.58 169.82 468.58 186.19 476.22 186.19 476.22 147.5 468.58 147.5 468.58 162.64 460.83 162.64 460.83 147.5 453.18 147.5"/>
                <path className="cls-1" d="M494.95,173.62h-5.39l1.64-10.32.82-5.85h.1l.87,5.85,1.95,10.32M488.33,147.5l-8.98,38.69h7.6l1.38-6.52h7.75l1.44,6.52h8.16l-9.29-38.69h-8.06Z"/>
                <polyline className="cls-1" points="510.59 147.5 507.21 186.19 515.42 186.19 516.55 158.33 516.65 158.33 516.65 159.05 517.93 167.67 521.68 186.19 527.58 186.19 530.86 167.88 532.1 158.33 532.2 158.33 534.05 186.19 541.9 186.19 537.84 147.5 527.58 147.5 524.5 170.34 524.4 170.34 520.65 147.5 510.59 147.5"/>
                <path className="cls-1" d="M553.44,153.81h1.44c3.29,0,4.36,2.26,4.36,5.03,0,3.95-2.11,5.34-5.8,5.18v-10.21M553.44,186.19v-15.96l1.69.1c7.08,0,11.75-4.16,11.75-11.39,0-12.47-11.5-11.45-13.14-11.45h-7.96v38.69h7.65Z"/>
                <rect className="cls-1" x="570.78" y="147.5" width="7.65" height="38.69"/>
                <path className="cls-1" d="M590.63,166.85c0-3.44.36-12.88,5.44-12.88s5.44,9.44,5.44,12.88c0,3.08-.36,12.88-5.44,12.88s-5.44-9.8-5.44-12.88M596.07,146.89c-6.83,0-13.6,5.44-13.6,19.96s6.77,19.96,13.6,19.96,13.6-5.44,13.6-19.96-6.77-19.96-13.6-19.96Z"/>
                <path className="cls-1" d="M613.71,147.5v38.69h7.65v-17.76l-.05-1.59-.36-3.9.1-.1,9.44,23.35h7.23v-38.69h-7.65v17.4c0,2,.15,4,.62,5.85l-.1.1-9.54-23.35h-7.34"/>
                <path className="cls-1" d="M659.02,148.79c-2.1-1.23-4.31-1.9-6.77-1.9-6.57,0-10.11,5.29-10.11,11.39,0,5.65,2.72,8.52,7.14,11.5,2.15,1.54,4.51,2.67,4.51,5.64,0,2.57-1.95,4.21-4.41,4.21-2.26,0-4-.82-5.8-2.06v7.29c1.75,1.28,4.41,1.95,6.57,1.95,7.18,0,11.14-5.18,11.14-12.06,0-10.47-11.65-10.83-11.65-17.19,0-2.36,1.49-3.8,3.85-3.8,2.1,0,4,1.13,5.54,2.47v-7.44"/>
                <polyline className="cls-1" points="665.69 147.5 665.69 186.19 673.34 186.19 673.34 169.82 681.09 169.82 681.09 186.19 688.74 186.19 688.74 147.5 681.09 147.5 681.09 162.64 673.34 162.64 673.34 147.5 665.69 147.5"/>
                <rect className="cls-1" x="694.17" y="147.5" width="7.65" height="38.69"/>
                <path className="cls-1" d="M714.9,153.81h1.44c3.28,0,4.36,2.26,4.36,5.03,0,3.95-2.1,5.34-5.8,5.18v-10.21M714.9,186.19v-15.96l1.69.1c7.08,0,11.75-4.16,11.75-11.39,0-12.47-11.5-11.45-13.14-11.45h-7.95v38.69h7.65Z"/>
                <path className="cls-1" d="M748.14,148.79c-2.11-1.23-4.31-1.9-6.77-1.9-6.57,0-10.11,5.29-10.11,11.39,0,5.65,2.72,8.52,7.13,11.5,2.15,1.54,4.51,2.67,4.51,5.64,0,2.57-1.95,4.21-4.41,4.21-2.26,0-4-.82-5.8-2.06v7.29c1.74,1.28,4.41,1.95,6.57,1.95,7.18,0,11.14-5.18,11.14-12.06,0-10.47-11.65-10.83-11.65-17.19,0-2.36,1.49-3.8,3.85-3.8,2.1,0,4,1.13,5.54,2.47v-7.44"/>
              </g>
              <path id="hand" className="cls-1" d="M181.51,182.9c-6.75-6.46-15.87-15.55-24.18-23.92l23.09-22.85v-33.89c-3.55.12-6.85.66-9.83,1.64,2.9-2.64,5.6-6.01,8.12-10.04l-23.73-12.98c7.9-17.88,12.37-37.65,12.5-58.45l10.84-.09L155.99,0l-22.33,22.68,12.01-.09c-.16,19.08-4.62,37.13-12.43,53.25l-9.29-1.28c-12.18-10.94-30.36-17.32-46.24-18.91l-12.35-12.06c-13.72,6.23-22.82,14.47-28.55,28.49l43.43,59.14c-17.23,9.32-36.9,14.7-57.81,14.93l-.09-11.46L0,157.02l22.68,22.33-.09-11.39c24.39-.26,47.36-6.49,67.52-17.29l2.74,65.96c13.46-.41,25.41-6.21,25.41-22.5,0-.85,0-1.77-.01-2.73l22.46,37.38c13.35-4.2,16.34-15.31,11.41-28.85-1.05-2.9-2.29-6.32-3.64-10.04l34.45,24.13c10.32-9.25,9.61-20.55-1.42-31.11ZM63.93,51.98l10.65,10.41c15.23,1.32,30.52,6.1,42.62,15.65,1.77,1.39,3.52,3.14,3.52,3.14l27.07,3.73,20.86,11.41c-8.43,10.3-18.77,7.58-26.89,1.68-6.29,3.83-12.54,4.84-19.62,5.38l-1.81,19.61c8.4,3.65,14.47,10.3,20.11,17.22,4.47-3.22,8.9-5.95,14.07-7.95,3.21-1.24,8.12-2.45,8.12-2.45v-2.75c0-8.07,3.22-14.42,10.8-16.92v23.06l-23.57,23.33-49.19-9.35-55.84-76.05c3.52-7.34,10.19-14.53,19.12-19.15ZM161.72,109.31c-3.37,3.52-5.52,8.46-6.01,15.11-4.9,1.56-9.66,3.68-14.03,6.41-4.02-4.47-8.72-9.09-13.94-12.07l.83-8.95c4.4-.63,8.85-1.62,12.88-3.55,7.55,4.3,14.27,5.08,20.27,3.06ZM111.26,194.12c0,10.27-7.23,13.7-11.72,14.84l-2.17-52.32,13.71,22.82c.11,5.47.18,10.59.18,14.66ZM145.54,202.32c2.06,5.66,3.24,13.22-2.02,17.54l-34.69-57.74,29.69,20.79c2.59,7.2,5.09,14.11,7.02,19.41ZM181.9,204.74l-68.55-48.01,38.8,7.37.19-.19c8.2,8.26,17.37,17.4,24.32,24.05,4.62,4.42,8.6,10.57,5.24,16.77Z"/>
            </svg>
          </a>

        </div>
        <div id="aText" ref={textAreaRef}>
            <h2 ref={h2Ref} id="airHeading" style={{color:"#BFB3D8"}}>{t("airHeading")}</h2> 
            <span id="aTextCont" ref={textContentRef}>
              {textVar}
            </span>        
          </div>
      </div>
    </section>
  );
}


