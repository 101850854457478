import "./Mobilenavbar.scss";
import React, { useLayoutEffect, useRef } from 'react';
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import gsap from 'gsap';

const MobileNavbar = ({ screenIndex }) => {
    const navRef = useRef(null); // Reference to the nav element
    const {t} = useTranslation("global");

    const colors = [
        "#FFFFFF", // Color for colorRef 0
        "#59776E", // Color for colorRef 3
        "#A8E0F9", // Color for colorRef 2
        "#BFB3D8",  // Color for colorRef 4
        "#FCEE21", // Color for colorRef 1
    ];

    useLayoutEffect(() => {
        colors.forEach((textColor, i) => {
            gsap.to(`#link${i + 1}`, {
                duration: 1,
                color: i === screenIndex ? textColor : "#a19d9d",
            });
        });
    }, [screenIndex]);

    const renderLinks = () => {
        return colors.map((color, i) => (
            <Link
                key={i}
                to={`section${i + 1}`}
                className='link'
                id={`link${i + 1}`}
                smooth={true}
                duration={1000}
            >
                {i === 0 ? 'Δ' : i === 1 ? t("earthHeadings") : i === 2 ? t("wadaHeadings") : i === 3 ? t("airHeadings") : t("fireHeadings")}
            </Link>

        ));
    };

    return (
        <nav ref={navRef}>
            {renderLinks()}
        </nav>
    );
}

export default MobileNavbar;
