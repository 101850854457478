import React, { useRef, useLayoutEffect, useState } from 'react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/all";
import { gsap } from 'gsap';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from "three";
import { easing } from 'maath';
import * as dat from 'dat.gui';

import useCloneStore from '../zustand/useCloneStore';
import useBreathingStore from '../zustand/useBreathingStore';
import useOrbitStore from '../zustand/useOrbitStore';
import { Vector3 } from 'three'; // Assuming you're using three.js for Vector3
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP);

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin); 

export default function Model(props) {

    const { breathing, setBreathing } = useBreathingStore();
    const {
        viewportAtTop,
      } = useOrbitStore();
    const { cloneValue, setCloneValue } = useCloneStore();

    const toggleBreathing = (state) => {
        setBreathing(state);
    };
  
    const { onObjectLoad, camRef, onColorRefChange, show, sceneRef, winWid } = props;
    const [colorRef, setColorRef] = useState(0)
    const object = useGLTF(`./${props.name}`); 
    const previousTimeRef = useRef(0);
    const groupRef = useRef(null);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); //to check if user is on desktop/mobile, for object to follow the cursor

    const [initialProgress, setInitialProgress] = useState(0);
    const [initialRotation, setInitialRotation] = useState(new Vector3(0, 0, 0));
    const [initialPosition, setInitialPosition] = useState(new Vector3(0, 0, 0));
    
    const animationFrameId = useRef(null);

    const initialScale = 1;

    useLayoutEffect(() => {
        if ( breathing) {
            const timeoutId = setTimeout(() => {
                let time = 0; // Initialize time variable
              
                const animateCamera = () => {
                    if (breathing) {
                        let cameraX, cameraY, cameraZ = 40, range, speed;

                        switch (colorRef) {
                            case 4:
                                range = 10;
                                speed = 0.01;
                                cameraX = Math.sin(time * speed) * range ;
                                cameraY = Math.sin(time * (speed * 0.5)) * (range* 0.5) ;
                                break;
                            case 1:
                                range = 5;
                                speed = 0.01;
                                cameraX = Math.sin(time * speed) * range;
                                cameraY = 0;
                                break;
                            case 2:
                                range = 5;
                                speed = 0.01;
                                cameraX = Math.sin(time * speed) * range;
                                cameraY = 0;
                                break;
                            case 3:
                                range = 2;
                                speed = 0.02;
                                cameraX = Math.sin(time * speed) * range;
                                cameraY = Math.cos(time * speed) * range;
                                break;
                            default: 
                            break;
                        }

                        // Apply easing to camera position
                        easing.damp3(
                            camRef.current.position,
                            [cameraX, cameraY, cameraZ],
                            0.2,
                            0.1
                        );
                        camRef.current.lookAt(0, 0, 0); // Make the camera look at the center of the scene

                        time++; // Increment time for animation
                    }

                    animationFrameId.current = requestAnimationFrame(animateCamera);
                };

                animateCamera(); // Start the animation loop
            }, 0); // Delay the execution by 1 second

            return () => {
                clearTimeout(timeoutId); // Clean up the timeout if followCursor changes
                cancelAnimationFrame(animationFrameId.current); // Stop the animation loop
            };
        }
    }, [breathing]);
    
    useLayoutEffect(() => {
        const colors = [
            0xFFFFFF, // Color for colorRef 0
            0x59776E, // Color for colorRef 3
            0xA8E0F9, // Color for colorRef 2
            0xBFB3D8,  // Color for colorRef 4
            0xFCEE21, // Color for colorRef 1
        ];
    
        object.scene.traverse((child) => {  //break object to all meshes if possible
            if (child instanceof THREE.Mesh) { //loop through the meshes and do something
                const colorIndex = Math.min(colorRef, colors.length - 1);
                colorTo(child, new THREE.MeshBasicMaterial({
                    color: colors[colorIndex]
                }));
            }
        });

        onColorRefChange(colorRef)
    }, [colorRef]);

    function colorTo(child, color) {
    var target = child;
    var initial = new THREE.Color(target.material.color.getHex());
    var value = new THREE.Color(color.color.getHex());

        gsap.to(initial, 1, {
            r: value.r,
            g: value.g,
            b: value.b,
            
            onUpdate: function () {
            target.material.color = initial;
            }
        });
    }

    useLayoutEffect(() => {
        if (object.scene) {

            const group = new THREE.Group();
            group.add(object.scene);
            object.scene.position.set(1.2, -3, 0); 
            group.rotation.set(-0.2, -1, 0); 

            object.scene = group;
            groupRef.current = group;

            onObjectLoad(groupRef);

        }
    }, [props.name]);

    useFrame(() => {
        //make speed of spinning the object independent of graphics resources
        const currentTime = performance.now();
        const deltaTime = (currentTime - previousTimeRef.current) / 1000; 
        previousTimeRef.current = currentTime;

        // Check if the object scene exists and rotation is enabled
        if (object.scene && viewportAtTop) {
            // Calculate the target rotation angle in radians
            const targetRotationY = object.scene.rotation.y + (0.3 *deltaTime);
            // Set the rotation directly to the target angle in degrees
            object.scene.rotation.y = targetRotationY % (2 * Math.PI); // Ensure rotation stays within 0 to 2π radians range
        }
    });

    //logic for clearing the timeline. This helps animations. Since animations are saved by the browser 
    //Animations 
    // seperate first time line from others because it should be killed everytime user scrolls back to top. Seperate because we don't want to kill the other timelines. fuck maybe we want to
    useLayoutEffect(() => {
    if (show) {
        const commonTimelineConfig = {
            onComplete: () => toggleBreathing(true),
            onUpdate: () => {
                toggleBreathing(false);
                setColorRef(tl2.progress() > 0.85 ? 1 : 0);
            }
        };
    
        const scrollTriggerConfig = isMobile 
            ? {
                trigger: "#section4",
                start: "top-=50% top+=50%",
                end: "top+=40% center",
                scrub: true,
                fastScrollEnd: true,
            }
            : {
                trigger: "#section4",
                start: "top-=50% top+=50%",
                end: "center center",
                scrub: true,
                snap: true,
                fastScrollEnd: true,
            };
    
        let tl2 = gsap.timeline({
            ...commonTimelineConfig,
            scrollTrigger: scrollTriggerConfig
        });
    
        tl2.to(object.scene.rotation, { x: -0.01, y: 0.79, z: 0.97 }, 0)
           .to(props.camRef.current.position, { x: 0, y: 0, z: 10 }, 0);
           
        return () => tl2.kill();

    }
    
}, [viewportAtTop, show]);
    
useLayoutEffect(() => {
    if (show) {
        const createTimeline = (trigger, startColor, endColor, animations, isMobile, progressCheck) => {
            const scrollTriggerConfig = isMobile
                ? {
                    trigger: trigger,
                    start: "top-=40% top+=50%",
                    end: "top+=40% center",
                    scrub: true,
                    fastScrollEnd: true,
                    // markers: true
                }
                : {
                    trigger: trigger,
                    start: "top-=50% top+=50%",
                    end: "center center",
                    scrub: true,
                    snap: true,
                    fastScrollEnd: true,
                    // markers: true
                };

            const tl = gsap.timeline({
                onStart: () => setColorRef(startColor),
                onComplete: () => toggleBreathing(true),
                onUpdate: function () {
                    toggleBreathing(false);
                    const progress = this.progress();
                    progressCheck(progress);
                },
                scrollTrigger: scrollTriggerConfig
            });

            animations.forEach(animation => tl.to(animation.target, animation.props, animation.position));
            return tl;
        };

        const progressCheck3 = (progress) => {
            if (progress > 0.5) {
                setColorRef(2);
            } else if (progress === 0) {
                toggleBreathing(true);
            } else {
                setColorRef(1);
            }
        };

        const progressCheck4 = (progress) => {
            if (progress > 0.5) {
                setColorRef(4);
            } else if (progress === 0) {
                toggleBreathing(true);
            } else {
                setColorRef(3);
            }
        };

        const progressCheck5 = (progress) => {
            if (progress > 0.5) {
                setColorRef(3);
            } else if (progress === 0) {
                toggleBreathing(true);
            } else {
                setColorRef(2);
            }
        };

        const progressCheck6 = (progress) => {

            if (progress > 0.5) {
                setColorRef(0);
            } else {
                setColorRef(4);
            }

        };

        const tl3 = createTimeline("#section3", 2, 2, [
            { target: object.scene.rotation, props: { x: 1.95, y: -0.7, z: 1.51 } },
            { target: object.scene.position, props: { x: 0, y: 1, z: 0 }, position: 0 },
            { target: props.camRef.current.position, props: { x: 0, y: 0, z: 10 }, position: 0 }
        ], isMobile, progressCheck3);

        const tl4 = createTimeline("#section2", 2, 3, [
            { target: object.scene.rotation, props: { x: 0.01, y: -0.5, z: 0 } },
            { target: object.scene.position, props: { x: 0, y: 1, z: 0 }, position: 0 },
            { target: props.camRef.current.position, props: { x: 0, y: 0, z: 10 }, position: 0 }
        ], isMobile, progressCheck4);

        const tl5 = createTimeline("#section5", 3, 4, [
            { target: object.scene.rotation, props: { x: 1.53, y: -0.19, z: 3.12 } },
            { target: object.scene.position, props: { x: 0, y: 1, z: 0 }, position: 0 },
            { target: props.camRef.current.position, props: { x: 0, y: 0, z: 10 }, position: 0 }
        ], isMobile, progressCheck5);
        
        // Custom ScrollTrigger for #sectionoulu
        const tlOulu1 = gsap.timeline({
            onUpdate: function () {
                toggleBreathing(false);
                const progress = this.progress();
                progressCheck6(progress);
            },
            scrollTrigger: {
                trigger: "#sectionoulu",
                start: 'top bottom-=10%',
                end: 'top+=25% bottom-=10%',
                scrub: true,
                onUpdate: (self) => {
                    let selfProgress = self.progress;
                    const scale = gsap.utils.interpolate(initialScale, 0.4, selfProgress);
                    setInitialProgress(self.progress)

                    // Adjust model scale based on progress
                    if (object.scene) {
                      object.scene.scale.set(scale, scale, scale);
                      setInitialRotation(object.scene.rotation)
                      setInitialPosition(object.scene.position)
                    }
            
                    // Run the code when selfProgress === 1 only once
                    if (selfProgress === 1) {
                      setLoadingClones(true);
                    }
                  
                    // Reset the flag when selfProgress === 0
                    if (selfProgress === 0) {
                      setLoadingClones(false);
                    }
                }
            }
        });

        // Call this function initially to store the model's initial rotation
        // Define animations for #sectionoulu
        // tlOulu1.to(object.scene.rotation, { x: 0, y: 0, z: 0}, 0)
        tlOulu1.to(object.scene.rotation, { x: 0.65, y: 0.53, z: -3.15 }, 0) //OLD
                .to(camRef.current.position, { x: 0, y: 0, z: 10 }, 0) 

        return () => {
            tl3.kill();
            tl4.kill();
            tl5.kill();
            tlOulu1.kill();
        };
    }
}, [viewportAtTop, show]);

//stuff for clones
    const [clones, setClones] = useState([]);
    const [loadingClones, setLoadingClones] = useState(false);

    useGSAP(()=>{ //used for controlling loading and removing of clones

        if (clones.length === 0) {
          if (loadingClones) {
            loadClones(7);
          } 
        } 
    
        if (clones.length > 0) { 
          if (!loadingClones) {
            cleanupClones();
          }
        }
    
    }, [clones, loadingClones])

    useGSAP(()=>{ //CLONES out and in ROTATION + SCALE

        if (clones.length === 0) return;

        let width = window.innerWidth;
        const scale = gsap.utils.interpolate(initialScale, 0.4, initialProgress);

        clones.forEach((clone) => {
            clone.scale.set(scale, scale, scale);
            clone.rotation.set(initialRotation.x, initialRotation.y, initialRotation.z);
        });
                
    }, [clones, initialProgress, initialRotation, initialPosition])

    useLayoutEffect(()=>{
        setCloneValue(clones.length)
    }, [clones.length])


    useGSAP(() => {

        const fromRotation = { x: 0.65, y: 0.53, z: -3.15 }; //OLD
        // const fromRotation = { x: 0, y: 0, z: 0 };
        const targetRotation = [
          { x: 0.9, y: -2.65, z: -3.11 }, //NEW U
          { x: 0, y: -2.11, z: 0 }, //NEW L
          { x:  0.9, y: -2.65, z: -3.11 }, //NEW U
          { x: -0.86, y: 2.38, z: -2.07 }, // NEW 2
          { x: 0.61, y: 0.53, z: -3.15 }, // NEW 0
          { x: -0.86, y: 2.38, z: -2.07 }, // NEW 2
          { x: 0.43, y: 0.86, z: 1.69 } //NEW 6
        ];

        if (clones.length === 0) return;
        let width = window.innerWidth;

        const modelWidth = 1; // Assuming the width of the model is 2 units (adjust as needed)
    
        // Calculate total width based on screen width and model
        let spacing = 3.33;
        const totalClones = clones.length;
        const rowWidth = (totalClones - 1) * spacing;
        const totalWidth = modelWidth + spacing + rowWidth;
    
        const scrollTrigger2 = ScrollTrigger.create({
          trigger: "#sectionoulu",
          start: 'top+=25% bottom-=10%',
          end: 'top+=50% bottom-=10%',
          scrub: true,
          onUpdate: (self) => {
            const selfProgress = self.progress;
    
            // Animate the original model's X position
            const finalModelXPosition = (-totalWidth / 2) + (modelWidth / 2);
            gsap.to(groupRef.current.position, {
              x: gsap.utils.interpolate(0, finalModelXPosition, selfProgress),
              ease: "none",
              overwrite: true
            });
    
            // Loop through each clone to animate its position along the X-axis
            clones.forEach((clone, index) => {
                const isSpecialIndex = index === 4;
                let finalXPosition = (-totalWidth / 2) + modelWidth + spacing + index * spacing;
                // Add extra 0.2 units if the index is 4
                if (isSpecialIndex) {
                  finalXPosition += 0.4;
                }
                // Animate the position with GSAP
                gsap.to(clone.position, {
                  x: gsap.utils.interpolate(0, finalXPosition, selfProgress),
                  ease: "none",
                  overwrite: true
                });
            });
          }
        });
    
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: "#sectionoulu",
            start: 'top+=50% bottom-=10%',
            end: 'bottom-=10% bottom-=10%',
            scrub: true,
            onUpdate: (self) => {
              const selfProgress = self.progress;
              // Loop through each clone to animate its rotation
              clones.forEach((clone, index) => {
                gsap.to(clone.rotation, {
                  x: gsap.utils.interpolate(fromRotation.x, targetRotation[index].x, selfProgress),
                  y: gsap.utils.interpolate(fromRotation.y, targetRotation[index].y, selfProgress),
                  z: gsap.utils.interpolate(fromRotation.z, targetRotation[index].z, selfProgress),
                  ease: "none",
                  overwrite: true
                });
              });
            }
          }
        });
    
        return () => {
          tl.kill();
          scrollTrigger2.kill();
        };
      }, [clones]); 

      function loadClones(numberOfClones) {
        // Get the existing clones (assuming setClones is a state updater function)
        setClones((prevClones) => {
          const newClones = [];
          
          // Create new clones and add them to the newClones array
          for (let i = 0; i < numberOfClones; i++) {
            const clone = object.scene.clone();
            sceneRef.add(clone);
            newClones.push(clone);
          }
          
          // Combine existing clones with the new clones
          return [...prevClones, ...newClones];
        });
      }
    
      function cleanupClones() {
        if (clones.length > 0) {
      
          clones.forEach((clone, index) => {
            
            sceneRef.remove(clone); 
           
          });
      
          setClones([]);
        }
      };

  return (
    <primitive object={object.scene} />
  );
}