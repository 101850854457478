import { create } from 'zustand';

const useBreathingStore = create((set) => ({
  breathing: false,

  // Action to set the breathing state
  setBreathing: (breathingState) => set({ breathing: breathingState }),
}));

export default useBreathingStore;
