import { create } from 'zustand';

const useAnimationStore = create((set) => ({
  headingAnimationFinished: false, // Initial state is false

  // Action to set the headingAnimationFinished state
  setHeadingAnimationFinished: (isFinished) => set({ headingAnimationFinished: isFinished }),
}));

export default useAnimationStore;
