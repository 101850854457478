import React, { useLayoutEffect, useEffect, useState, useRef } from "react";
import SplitType from "split-type";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import "./Frontpage.scss";

import useAnimationStore from "../../zustand/useAnimationStore";

gsap.registerPlugin(ScrollTrigger, ScrambleTextPlugin);

export default function Frontpage(props) {
  const [textVar, setTextVar] = useState("");
  const [textVar2, setTextVar2] = useState("");
  const [textVar3, setTextVar3] = useState(""); // Added third textVar
  const { headingAnimationFinished, setHeadingAnimationFinished } = useAnimationStore();
  const [t, i18n] = useTranslation("global");

  const sectionRef = useRef(null);
  const text1Ref = useRef(null);
  const text2Ref = useRef(null);
  const text3Ref = useRef(null); // Added ref for textVar3
  const targetRef = useRef(null); // Reference for color animation

  const colors = [
    'rgba(89, 119, 110,1)',  // Color for colorRef 3
    'rgba(168, 224, 249, 1)', // Color for colorRef 2
    'rgba(191, 179, 216, 1)', // Color for colorRef 4
    'rgba(252, 238, 33, 1)'   // Color for colorRef 1
  ];

  const [currentColorIndex, setCurrentColorIndex] = useState(0);

  // Color toggling logic
  useEffect(() => {
    const targetElement = document.querySelector(".link");

    if (!targetElement) return;

    function toggleColor() {
      const nextColorIndex = (currentColorIndex + 1) % colors.length;

      gsap.to(targetElement, {
        color: colors[nextColorIndex],
        duration: 0.1,
        ease: "power2.inOut",
      });

      setCurrentColorIndex(nextColorIndex);
    }

    const interval = setInterval(() => {
      toggleColor();
    }, 750);

    return () => clearInterval(interval);
  }, [currentColorIndex, colors]);

  useLayoutEffect(() => {
    // Set text variables using translations
    const headerText = t("headerS");
    setTextVar(headerText + t("fpt1")); // First text block
    setTextVar2(t("fpt2")); // Second text block
    setTextVar3(t("fpt3")); // Third text block
  }, [t, i18n.language]);

  useLayoutEffect(() => {
    const splitText = new SplitType(props.h1Ref.current, {
      types: "chars",
    });

    const chars = splitText.chars;
    const chance = Math.random() < 0.2 ? "shuffle" : "reverse";

    if (chance === "reverse") chars.reverse();
    if (chance === "shuffle") gsap.utils.shuffle(chars);

    const deltaIndex = chars.findIndex((char) => char.textContent === "Δ");
    if (deltaIndex !== -1) {
      const deltaChar = chars.splice(deltaIndex, 1)[0];
      chars.unshift(deltaChar);
    }

    gsap.to(chars, {
      y: "0%",
      filter: "blur(0px)",
      opacity: 1,
      stagger: 0.15,
      delay: 0.5,
      duration: 0.5,
      scale: 1,
      onComplete: () => animateHeadingMore(props.h1Ref.current),
    });

    return () => {
      splitText.revert();
    };
  }, []);

  function animateHeadingMore(element) {
    const tl = gsap.timeline();

    tl.to(element, {
      opacity: 0,
      duration: 1,
      onComplete: () => {
        element.classList.add("final");
      },
    });

    tl.to(element, {
      opacity: 1,
      duration: 0.5,
      delay: 0.5,
      onComplete: () => {
        setHeadingAnimationFinished(true);
      },
    });
  }

  useLayoutEffect(() => {
    if (headingAnimationFinished) {
      let timelines = [];
      let splitText, splitText2, splitText3;

      const breakpoints = [
        { width: 768, animationConfig: { start: "top center-=10%", end: "bottom center-=10%" } },
        { width: 1121, animationConfig: { start: "top center+=15%", end: "bottom center+=15%" } },
        { width: 9999, animationConfig: { start: "top top+=20%", end: "bottom top+=20%" } },
      ];

      const breakpoint = breakpoints.find((bp) => props.winWid < bp.width);

      if (breakpoint) {
        setTimeout(() => {
          splitText = new SplitType(text1Ref.current, { types: "lines" });
          splitText2 = new SplitType(text2Ref.current, { types: "lines" });
          splitText3 = new SplitType(text3Ref.current, { types: "lines" });

          const lines = splitText.lines;
          const lines2 = splitText2.lines;
          const lines3 = splitText3.lines;

          lines.forEach((line) => {
            const timeline = gsap.to(line, {
              opacity: 0,
              scrollTrigger: {
                trigger: line,
                ...breakpoint.animationConfig,
                scrub: true
              },
            });
            timelines.push(timeline);
          });

          lines2.forEach((line) => {
            const timeline = gsap.to(line, {
              opacity: 0,
              scrollTrigger: {
                trigger: line,
                ...breakpoint.animationConfig,
                scrub: true
              },
            });
            timelines.push(timeline);
          });

          lines3.forEach((line) => {
            const timeline = gsap.to(line, {
              opacity: 0,
              scrollTrigger: {
                trigger: line,
                ...breakpoint.animationConfig,
                scrub: true,
              },
            });
            timelines.push(timeline);
          });
        }, props.isMobile ? 100 : 0);
      }

      return () => {
        timelines.forEach((timeline) => {
          timeline.kill();
        });
        if (splitText) splitText.revert();
        if (splitText2) splitText2.revert();
        if (splitText3) splitText3.revert();
      };
    }
  }, [props.winWid, headingAnimationFinished, i18n.language, props.isMobile]);

  return (
    <section ref={sectionRef} className="full-width" id="section1">
      <div
        className="front-page-stuff"
      >
        <p
          id="fpt2"
          className="fpText2"
          ref={text2Ref}
          dangerouslySetInnerHTML={{ __html: textVar2 }}
        ></p>
        <p
          id="fpt1"
          className="fpText"
          ref={text1Ref}
          dangerouslySetInnerHTML={{ __html: textVar }}
        ></p>
        <p
          id="fpt3"
          className="fpText3"
          ref={text3Ref}
          dangerouslySetInnerHTML={{ __html: textVar3 }}
        ></p>
      </div>
    </section>
  );
}
