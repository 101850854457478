import React from 'react';
import useLanguage from './useLanguage'; // Import the custom hook for language management
import './LanguageGlobe.scss';

function LanguageGlobe( {isMobile}) {
  const { language, changeLanguage } = useLanguage(); // Using the custom hook to manage language

  return (
    <div onClick={isMobile ? changeLanguage : null} className='globeWrapper'>
      <div id='langTextWrapper'>
        <p className='langText'>{language.toUpperCase()}</p>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 120 120" 
        xmlSpace="preserve"
        className='globeSvg'
        onClick={isMobile ? null : changeLanguage }
        style={{ pointerEvents: isMobile ? 'none' : 'auto' }}
        >
        <switch>
          <g>
            <path d="M60 120C26.9 120 0 93.1 0 60S26.9 0 60 0s60 26.9 60 60-26.9 60-60 60zM60 5C29.7 5 5 29.7 5 60s24.7 55 55 55 55-24.7 55-55S90.3 5 60 5z"/>
            <path d="M60 120c-19.3 0-34.4-26.4-34.4-60S40.7 0 60 0s34.4 26.4 34.4 60-15.1 60-34.4 60zM60 5C43.8 5 30.5 29.7 30.5 60s13.2 55 29.5 55 29.5-24.7 29.5-55S76.2 5 60 5z"/>
            <path d="M12.2 25.6h95.6v5H12.2zM12.2 89.5h95.6v5H12.2zM2.5 57.5h115v5H2.5z"/>
            <path d="M57.5 2.5h5v115h-5z"/>
          </g>
        </switch>
      </svg>
    </div>
  );
}



export default LanguageGlobe;
