import React from 'react';

const LogoLink = ({ href, src, alt, title, id, classN, wid, hei }) => {
  return (
    <a className={classN} id={id} href={href} target="_blank" rel="noopener noreferrer">
      <img src={src} alt={alt} title={title} width={wid} height={hei} />
    </a>
  );
};

export default LogoLink;
