import { create } from 'zustand';

const useCloneStore = create((set) => ({
  cloneValue: 0, // Initial integer value

  // Action to set the value directly
  setCloneValue: (value) => set({ cloneValue: value }),

}));

export default useCloneStore;
