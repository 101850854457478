import React, { useRef, Suspense, useState, useEffect, useLayoutEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import Model from "./Model";
import gsap from "gsap";
import { useGSAP } from '@gsap/react';
import { OrthographicCamera } from '@react-three/drei';
import useOrbitStore from '../zustand/useOrbitStore';

gsap.registerPlugin(useGSAP);

function Scenebg({passColor, headingAnimationFinished, winWid, passSceneModel}) {

  const {
    orbitControlsEnabled,
    setOrbitControlsEnabled,
  } = useOrbitStore();

  const objectRef = useRef();
  const glRef = useRef();
  const canvasRef = useRef();
  const canvasWrapperRef = useRef();
  const sceneRef = useRef();
  const cameraRef = useRef();
  const orbitRef = useRef();

  const [showModel, setShowModel] = useState(false);
  const [colorIndex, setColorIndex] = useState(0);

  const colors = [
    'rgba(255, 255, 255, 0.5)', // Color for colorRef 0
    'rgba(89, 119, 110, 0.5)',  // Color for colorRef 3
    'rgba(168, 224, 249, 0.5)', // Color for colorRef 2
    'rgba(191, 179, 216, 0.5)',  // Color for colorRef 4
    'rgba(252, 238, 33, 0.5)',  // Color for colorRef 1

  ];

  const handleColorRefChange = (colorRef) => {
    if(passColor) {
      passColor(colorRef)
    }
    setColorIndex(colorRef);
  };

  const getZoom = (width) => { //get desired zoom of camera based on window width
    switch (true) {
      case width <= 330:
        return 20;
      case width <= 500:
        return 20;
      case width <= 768:
        return 20;
      case width <= 1120:
        return 30;
      case width <= 1920:
        return 40;
      case width > 1920:
        return 50;
      default:
        return 30;
    }
  };

  useEffect(() => {
    if (!cameraRef.current) return; // Exit if cameraRef.current is not set

    const updatedZoom = getZoom(winWid); // Update zoom when window width changes
    cameraRef.current.zoom = updatedZoom;

  }, [winWid, cameraRef.current]); // Run when winWid or cameraRef.current changes

  useGSAP(()=> {
    if (headingAnimationFinished) {
      gsap.to(cameraRef.current.position, {
        z: 50,
        duration: 1, // Adjust duration as needed
        ease: "power4.inOut",
        onComplete: ()=> {
          setShowModel(true)
          setOrbitControlsEnabled(true)

          gsap.to(".fpText2", {
            opacity: 1,
            duration: 2,
          });

          gsap.to(".fpText", {
            opacity: 1,
            duration: 1.5,
            delay: 1
          });
      

          gsap.to(".fpText3", {
            opacity: 1,
            duration: 1.5,
            delay: 2.5
          });
        }
      });
    }
  }, [headingAnimationFinished])

  useGSAP(() => {

    if(headingAnimationFinished && winWid < 501 ) {
      gsap.to(canvasWrapperRef.current, {
        duration: 1,
        delay: 0.25,
        background: `linear-gradient(to bottom, ${colors[colorIndex]}, transparent 70%)`
      })
    } else if (headingAnimationFinished && winWid > 501 && winWid < 769 ){
      gsap.to(canvasWrapperRef.current, {
        duration: 1,
        delay: 0.25,
        background: `linear-gradient(to bottom, ${colors[colorIndex]}, transparent 80%)`
      })
    } else if (headingAnimationFinished) {
      gsap.to(canvasWrapperRef.current, {
        duration: 1,
        delay: 0.25,
        background: `radial-gradient(ellipse, ${colors[colorIndex]}, transparent 80%)`
      })
    }
  }, [ headingAnimationFinished ,colorIndex, winWid]);

  const handleCreated = (gl, camera, scene) => {
    glRef.current = gl; // Save gl to useRef
    cameraRef.current = camera; // Save camera to useRef
    sceneRef.current = scene; // Save scene to useRef
  };

  const getObjectRef = (object) => {
    objectRef.current = object;
  };

  useEffect(()=>{

    if (sceneRef.current && objectRef.current && cameraRef.current) {
      passSceneModel(sceneRef.current, objectRef.current, cameraRef.current);
    }

  }, [sceneRef.current, objectRef.current])

  return (
    <div
     className="canvasWrapper"
     ref={canvasWrapperRef}
    >
      <Canvas
      id="myCanvas"
      gl={{ alpha:true, antialias: true }}
      style={{ }}
      onCreated={({ gl, camera, scene }) => {
        handleCreated(gl, camera, scene); // Pass arguments to handleCreated
      }}
      ref={canvasRef}
      >
        <OrthographicCamera
          makeDefault
          ref={cameraRef}
          near={0.1}
          far={100}
          position={[0, 0, 150]}
          onUpdate={(self) => self.updateProjectionMatrix()} // Ensure camera updates
          zoom={getZoom(winWid)}
        />
        <ambientLight  intensity={2} />
        <Suspense fallback={null}>  
          <Model winWid={winWid} show={showModel} id="model" name={"Delta.glb"} onColorRefChange={handleColorRefChange} onObjectLoad={getObjectRef}  sceneRef={sceneRef.current} camRef={cameraRef}/>
        </Suspense>
        <OrbitControls ref={orbitRef} enableRotate={orbitControlsEnabled} enableZoom={false} enablePan={false} />
      </Canvas>
    </div>
  );
}

export default Scenebg;
