import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';

import './Oulu.scss';
import useCloneStore from '../../zustand/useCloneStore';
import useBreathingStore from '../../zustand/useBreathingStore';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(ScrollTrigger, ScrambleTextPlugin, useGSAP);

export default function Oulu({ model, scene, h1Ref }) {
  const { cloneValue, setCloneValue } = useCloneStore();
  const { breathing, setBreathing } = useBreathingStore();
  const sectionRef = useRef(null);

  useEffect(()=>{

    //console.log(cloneValue)

  }, [cloneValue])
 
  useGSAP(() => { //BREATHIING DISABLED FOR OULU

    if (sectionRef.current === null) return;

    const scrollTrigger = ScrollTrigger.create({
      trigger: sectionRef.current,
      start: 'top bottom-=10%', 
      end: 'bottom bottom',
      scrub: true,
      onEnter: () => {setBreathing(false); console.log("breathing in oulu", breathing)},
      onLeaveBack: () => {setBreathing(true); console.log("breathing in oulu", breathing)},
    });

  }, [useBreathingStore, setBreathing, sectionRef]);



  return (
    <section id="sectionoulu" ref={sectionRef}>
      
    </section>
  );
}

//<h1 id="clone-data">{cloneValue}</h1>

