import React, { useRef, useState, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import SplitType from 'split-type';
import './Vesi.scss';
import { useTranslation } from "react-i18next";
import LogoLink from '../../components/LogoLink'


export default function Frontpage(props) {
  const [anim, toggleAnim] = useState(true);
  const [textVar, setTextVar] = useState("");
  const [t, i18n] = useTranslation("global");
  const [breakpoint, setBreakpoint]= useState(null);

  const sectionRef = useRef(null);
  const h2Ref = useRef(null);
  const logoGridRef = useRef(null);
  const textContentRef = useRef(null);
  const textAreaRef = useRef(null);

  const currentLng = i18n.language;

  useLayoutEffect(()=> {
    if (props.show) {
      setBreakpoint(props.breakpoint)
    }
  }, [props.breakpoint, props.show])

  const animateHeading = (element, language) => {
  if (language === "fi") {
    element.innerHTML = `VESI tarinat`;
  } else if (language === "en") {
    element.innerHTML = `W<span class="lsa">A</span><span class="lsa2">TER stories</span>`;
  }

  // Initialize SplitType instance after innerHTML is updated
  const splitText = new SplitType(element, {
    types: 'chars',
    charClass: 'charVesi'
  });

  const chars = splitText.chars;

  chars.forEach((char, index) => {
    const tl = gsap.timeline({ repeat: 1, delay: index * 0.05 });
    tl.to(char, {
      duration: 0.5,
      y: -5,
      ease: "power1.inOut",
    }).to(char, {
      duration: 0.5,
      y: 0,
      ease: "power1.inOut",
    });
  });

  // Return a cleanup function to revert the SplitType instance
  return () => {
    splitText.revert();
  };
};

  useLayoutEffect(() => {
    if (h2Ref.current) {
      const cleanup = animateHeading(h2Ref.current, currentLng);
      
      // Clean up SplitType instance when component unmounts or dependencies change
      return cleanup;
    }
  }, [anim, currentLng]);

  useLayoutEffect(() => {

    setTextVar(t("wText"));

  }, [t, i18n.language]);

  useLayoutEffect(() => {

    if (props.show) {
      // timeline for logos and text when user gets here
      gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "center-=20% center",
          onEnter: () => {
            gsap.to("#ojvLogo", {
              opacity: 1,
              duration: 1,
            });
            gsap.to(textAreaRef.current, {
              opacity: 1,
              duration: 1,
              delay: 0.5,
            });
            animateHeading(h2Ref.current, currentLng);
      
          },
          once:true,
        }
      });
    }
  }, [props.show, currentLng]);

  useLayoutEffect(() => {

    if (props.show) {
      const breakpoints = [
        { 
          width: 500, 
          animationConfig: { start: "top-=150% center-=10%", end: "bottom-=200% center-=10%" }
        },
        { 
          width: 768, 
          animationConfig: { start: "top-=150% center-=5%", end: "bottom-=200% center-=5%" }
        },
        { 
          width: 1121, 
          animationConfig: { start: "top-=100% center+=20%", end: "bottom-=100% center+=20%" }
        },
        { width: 9999, animationConfig: { start: "top top+=20%", end: "bottom top+=20%" } },
      ];


      let timelines = []; 
      let splitText; 

      if(breakpoint !== null) {
        setTimeout(() => {
          const desiredBpConfig = breakpoints.find(bp => window.innerWidth <= bp.width);
          
          if (desiredBpConfig) {
            splitText = new SplitType(textContentRef.current, {
              types: "lines"
            });
            
            const lines = splitText.lines;
            //text anim
            lines.forEach((line, index) => {
              const timeline = gsap.to(line, {
                opacity: 0,
                scrollTrigger: {
                  trigger: line,
                  ...desiredBpConfig.animationConfig,
                  scrub: true,
                  // markers: {startColor: "blueviolet", endColor: "blueviolet", fontSize: "18px"}
                  },
                });
                timelines.push(timeline); 
              });
            } 
          }, props.isMobile ? 100 : 0); 
        } 
      
      return () => {
        timelines.forEach((timeline) => {
          timeline.kill(); // Kill each timelin
        });
        // Revert SplitType instances to their original state
        if (splitText) splitText.revert();
      };
    
        
      } //if props show
    },[props.show, i18n.language, breakpoint, props.winWid, props.isMobile])



  //logo fading
  useLayoutEffect(()=> {

    if (props.show) {
      const breakpoints = [
        { 
          width: 500, 
          logoAnimationConfig: { start: "center center", end: "bottom center" } 
        },
        { 
          width: 768, 
          logoAnimationConfig: { start: "center center", end: "bottom center" } 
        }
      ];
    
      const desiredBpConfig = breakpoints.find(bp => breakpoint <= bp.width);
    
      let logoAnim = null;

      if (breakpoint !== null) {
        if (desiredBpConfig) {

          logoAnim = gsap.fromTo(
            logoGridRef.current,
            { opacity: 1 },  // The initial state (from)
            { 
              opacity: 0,  // The final state (to)
              scrollTrigger: {
                trigger: logoGridRef.current,
                ...desiredBpConfig.logoAnimationConfig,  // Use logoAnimationConfig for logo animation
                scrub: true,
              }
            });
            
          } else {
            gsap.to(logoGridRef.current, {opacity:1, duration: 1})
          }
        
          return () => {
            if(logoAnim !==null) {
              logoAnim.kill();
            }
          };
      }
    } //props show
  },[breakpoint, props.show])


  //h2 
  useLayoutEffect(()=> {

    if (props.show) {

      const breakpoints = [
            { 
              width: 500, 
              h2Config: {start:"top center-=5%", end:"bottom center-=5%"}
            },
            { 
              width: 768, 
              h2Config: {start:"top center", end:"bottom center"}
            },
            { 
              width: 1121, 
              h2Config: {start:"top-=100% center+=20%", end:"bottom-=100% center+=20%"}
            },
            { 
              width: 9999, 
              h2Config: { start: "top top+=20%", end: "bottom top+=20%" }
            }
          ];
  
          const desiredBpConfig = breakpoints.find(bp => breakpoint <= bp.width);
          let h2tl = null;
  
          if(breakpoint !== null) {
            
            if (desiredBpConfig) {
              // h2 anim
              h2tl = gsap.fromTo(
                h2Ref.current, 
                { opacity: 1 },
                {
                  opacity: 0,
                  scrollTrigger: {
                    trigger: h2Ref.current,
                    ...desiredBpConfig.h2Config,
                    scrub:true,
                    // markers: {startColor: "white", endColor: "white", fontSize: "18px"}
                  }
                })
            } 
            return () => {
              if(h2tl !==null) {
                h2tl.kill();
              }
            };
          }
    }
  },[props.show, breakpoint])


  return (
    <section ref={sectionRef} className="full-width" id="section3" onMouseDown={()=>toggleAnim(!anim)}>
        <div className='content'>
          <div id="logoGridW" ref={logoGridRef}>
            <LogoLink 
                href="https://www.oulunjuhlaviikot.fi/" 
                src="/images/OJV.svg"
                alt="Oulun juhlaviikot logo"
                title='Oulun juhlaviikot'
                id="ojvLogo"
                wid="100%"
                hei="100%"
              />    
          </div>
            
          <div id="wText" ref={textAreaRef}>
            <h2 id="waterHeading" ref={h2Ref} style={{color:"#A8E0F9"}}>VESI</h2>
            <span id="wTextCont" ref={textContentRef}>
              {textVar}
            </span>
          </div>
        </div>
    </section>
  );
}
