import { useState } from 'react';
import i18next from 'i18next';

function useLanguage() {
  const [language, setLanguage] = useState(i18next.language);

  const changeLanguage = () => {
    const newLanguage = language === 'fi' ? 'en' : 'fi'; // Alternating between 'fi' and 'en'
    i18next.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };

  return { language, changeLanguage };
}

export default useLanguage;