import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next'; // Language context
import i18next from 'i18next';
import App from './App';
import global_fi from './locales/fi/global.json'; // Language files
import global_en from './locales/en/global.json';

// Initialize i18next
i18next.init({
    interpolation: { escapeValue: false },
    lng: 'fi', // Default language
    resources: {
        fi: {
            global: global_fi,
        },
        en: {
            global: global_en,
        },
    },
});

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);
