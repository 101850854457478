import { create } from 'zustand';

const useOrbitStore = create((set) => ({
  orbitControlsEnabled: false,
  viewportAtTop: true,

  // Action to set orbitControlsEnabled
  setOrbitControlsEnabled: (enabled) => set({ orbitControlsEnabled: enabled }),

  // Action to set viewportAtTop
  setViewportAtTop: (atTop) => set({ viewportAtTop: atTop }),
}));

export default useOrbitStore;
