import React, { useRef, useLayoutEffect, useState } from 'react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/all";
import { gsap } from 'gsap';
import './Tuli.scss';
import { useTranslation } from "react-i18next";
import LogoLink from '../../components/LogoLink'
import SplitType from 'split-type';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin); 

export default function Tuli(props) {

  const [t, i18n] = useTranslation("global");

  const [textVar, setTextVar]= useState(t("fText"));
  const [breakpoint, setBreakpoint]= useState(null);

  const sectionRef = useRef(null);
  const h2Ref = useRef(null);
  const logoGridRef = useRef(null);
  const textContentRef = useRef(null);
  const textAreaRef = useRef(null);

  //update breakpoint based on what parent component think it should be
  useLayoutEffect(()=> {
    if (props.show) {
      setBreakpoint(props.breakpoint)
    }
  }, [props.breakpoint, props.show])

  useLayoutEffect(() => {
    if (props.show) {
  //when we come into section 2 reveal "fl, fire logos", then reveal the text underneath
      gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "center-=20% center",
          onEnter: () => {
            gsap.to(".fl", {
              opacity: 1,
              duration: 1,
            });
            gsap.to(textAreaRef.current, {
              opacity: 1,
              duration: 1,
              delay: 0.5
            });
          },
          once:true
        }
      });
  }

    
  }, [props.show]);

  useLayoutEffect(() => {

    setTextVar(t("fText"));

  }, [t, i18n.language]);

//text fading
useLayoutEffect(()=> {

  if (props.show) {

    let timelines = []; 
    let splitText; 

    const breakpoints = [
      { 
        width: 500, 
        animationConfig: { start: "top-=150% center-=10%", end: "bottom-=200% center-=10%" }
      },
      { 
        width: 768, 
        animationConfig: { start: "top-=150% center-=5%", end: "bottom-=200% center-=5%" }
      },
      { 
        width: 1121, 
        animationConfig: { start: "top-=100% center+=20%", end: "bottom-=100% center+=20%" }
      },
      { 
        width: 9999, 
        animationConfig: { start: "top-=100% top+=20%", end: "bottom-=100% top+=20%" }
      }
    ];

    if(breakpoint !== null) {
    setTimeout(() => {
      const desiredBpConfig = breakpoints.find(bp => window.innerWidth <= bp.width);
      
      if (desiredBpConfig) {
        splitText = new SplitType(textContentRef.current, {
          types: "lines"
        });
        
        const lines = splitText.lines;
        //text anim
        lines.forEach((line, index) => {
          const timeline = gsap.to(line, {
            opacity: 0,
            scrollTrigger: {
              trigger: line,
              ...desiredBpConfig.animationConfig,
              scrub: true,
              // markers: {startColor: "blueviolet", endColor: "blueviolet", fontSize: "18px"}
              },
            });
            timelines.push(timeline); 
          });
        } 
      }, props.isMobile ? 100 : 0); 
    } 
  
        return () => {
          timelines.forEach((timeline) => {
            timeline.kill(); // Kill each timelin
          });
          // Revert SplitType instances to their original state
          if (splitText) splitText.revert();
        };

    
  } //if props show
},[props.show, i18n.language, breakpoint, props.winWid, props.isMobile])

//h2 fading
useLayoutEffect(()=> {

  if (props.show) {

    const breakpoints = [
          { 
            width: 500, 
            h2Config: {start:"top center-=5%", end:"bottom center-=5%"}
          },
          { 
            width: 768, 
            h2Config: {start:"top center", end:"bottom center"}
          },
          { 
            width: 1121, 
            h2Config: {start:"top-=100% center+=20%", end:"bottom-=100% center+=20%"}
          },
          { 
            width: 9999, 
            h2Config: { start: "top top+=20%", end: "bottom top+=20%" }
          }
        ];

        const desiredBpConfig = breakpoints.find(bp => breakpoint <= bp.width);
        let h2tl = null;

        if(breakpoint !== null) {
          
          if (desiredBpConfig) {
            // h2 anim
            h2tl = gsap.fromTo(
              h2Ref.current, 
              { opacity: 1 },
              {
                opacity: 0,
                scrollTrigger: {
                  trigger: h2Ref.current,
                  ...desiredBpConfig.h2Config,
                  scrub:true,
                  // markers: {startColor: "white", endColor: "white", fontSize: "18px"}
                }
              })
          } 

          return () => {
            if(h2tl !==null) {
              h2tl.kill();
              // gsap.to("h2", {opacity:1, duration: 1}) //bring all h2 elements visible when there are no more breakpoints for h2, because likely opacity was stuck at 0
            }
          };
        }
  }
},[props.show, breakpoint])

//logo fading
useLayoutEffect(()=> {

  if (props.show) {
    const breakpoints = [
      { 
        width: 500, 
        logoAnimationConfig: { start: "top+=100% center", end: "bottom+=100% center" } 
      },
      { 
        width: 768, 
        logoAnimationConfig: { start: "top center", end: "bottom-=50% center" } 
      }
    ];
  
    const desiredBpConfig = breakpoints.find(bp => breakpoint <= bp.width);
  
    let logoAnim = null;

    if (breakpoint !== null) {
      if (desiredBpConfig) {

        logoAnim = gsap.fromTo(
          logoGridRef.current,
          { opacity: 1 },  // The initial state (from)
          { 
            opacity: 0,  // The final state (to)
            scrollTrigger: {
              trigger: logoGridRef.current,
              ...desiredBpConfig.logoAnimationConfig,  // Use logoAnimationConfig for logo animation
              scrub: true,
              // markers: {startColor: "blue", endColor: "blue", fontSize: "18px"}
            }
          });
          
        } else {
          gsap.to(logoGridRef.current, {opacity:1, duration: 1})
        }

        return () => {
          if(logoAnim !==null) {
            logoAnim.kill();
          }
        };
    }
  } //props show
},[breakpoint, props.show])

  return (
    <section ref={sectionRef} className="full-width" id="section2">
        <div className='content'>
          <div className="logoWrapper" ref={logoGridRef}>
            <LogoLink 
              href="https://brandstein.fi/"
              src="/images/B.svg"
              alt="Air Guitar World Championship logo"
              title="Air Guitar World Championship"
              classN="fl"
              wid="50%"
              hei="50%"
            />            
            <LogoLink 
              href="https://brandstein.fi/"
              src="/images/B.svg"
              alt="Air Guitar World Championship logo"
              title="Air Guitar World Championship"
              classN="fl"
              wid="50%"
              hei="50%"
            />            
            <LogoLink 
              href="https://brandstein.fi/"
              src="/images/B.svg"
              alt="Air Guitar World Championship logo"
              title="Air Guitar World Championship"
              classN="fl"
              wid="50%"
              hei="50%"
            />  
          </div>
          <div id="fText" ref={textAreaRef}>
            <h2 ref={h2Ref} id="fireHeading" style={{color:"#FCEE21"}}>{t("fireHeading")}</h2>
            <span id="fTextCont" ref={textContentRef}>
              {textVar}
            </span>
          </div>
        </div>
    </section>
  );
}